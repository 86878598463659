<template>
  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          lg="6"
          sm="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-group
            label="Registros por página"
          >
            <v-select
            v-model="perPage"
            :options="pageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-form-group>
        </b-col>
        <!-- New Route -->
        <b-col
          lg="6"
          sm="6"
          class="topTable"
        >
          <div class="d-flex align-items-right justify-content-end">
            <b-button
              @click="$router.push({ name: 'add-logisticRoute', params: { }})">
              Agregar ruta
            </b-button>
          </div>
        </b-col>
      </b-row>
      <hr style="margin-top: 15px">
      <!-- Filters -->
      <b-row>
        <b-col
          lg="6"
          sm="12"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-group
            label="Filtrar países"
          >
            <v-select
              v-model="filtersArray[0]"
              :options="countries"
              class="d-inline-block ml-50 mr-1"
              style="width: 218px"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          sm="12"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-group
            label="Filtrar estados"
          >
            <v-select
              v-model="filtersArray[1]"
              :options="states"
              class="d-inline-block ml-50 mr-1"
              style="width: 218px"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <!-- :items="fetchInvoices" -->
    <b-overlay
      :show="tableOverlay"
      rounded="sm"
    >
      <b-table
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="filteredData"
        @row-clicked="onRowClicked"
        ref="refInvoiceListTable"
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No hay registros"
        class="position-relative"
      >

        <!-- Column: Commissions -->
        <template #cell(commissions)="data">
          <div v-for="(commission, index) in data.item.commissions" :key="index">
            {{ commission.commissionName }}: ${{ formatterNumber(commission.price) }}
          </div>
        </template>

      </b-table>
    </b-overlay>
    <br>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>


<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/customHelios.scss';
</style>


<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BOverlay, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import utils from '../../../utils/utils.js';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    ToastificationContent,
    BOverlay,
    vSelect,
    BFormGroup,
  },
  data() {
    return {
      tableColumns: [
        { key: 'name', label: 'Ruta', sortable: true },
        { key: 'originCountry', label: 'País Origen', sortable: true },
        { key: 'originState', label: 'Estado Origen', sortable: true },
        { key: 'destinyCountry', label: 'País Destino', sortable: true },
        { key: 'destinyState', label: 'Estado Destino', sortable: true },
        { key: 'commissions', label: 'Coste gasto/s', sortable: false },
      ],
      perPage: 5,
      pageOptions: [5, 10, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'id',
      sortDesc: false,
      sortDirection: 'asc',

      // Filters
      countryFilter: null,
      stateFilter: null,
      filtersArray: [null, null],
      countries: [],
      states: [],

      // Data
      data: [],

      // Props
      tableOverlay: true,
    }
  },
  created() {
    this.getRoutes();
  },
  computed: {
    filteredData() {

      // Ignorar si el filtro es null
      if (this.filtersArray[0] == null)
        this.filtersArray[0] = '';
      if (this.filtersArray[1] == null)
        this.filtersArray[1] = '';

      // Filtrar rutas
      return this.data.filter(route => {
        return (route.originCountry.includes(this.filtersArray[0]) ||
                route.destinyCountry.includes(this.filtersArray[0])) &&
               (route.originState.includes(this.filtersArray[1]) ||
                route.destinyState.includes(this.filtersArray[1]))
      });
    },
  },
  methods: {
    getRoutes() {
      this.$axios.get(this.$services + 'logistics/get_routes').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.data = res.data.data;
          this.totalRows = res.data.data.length;
          this.setLocations();
          // QUitar duplicados
          this.countries = [...new Set(this.countries)];
          this.states = [...new Set(this.states)];
        } else
        throw 'Error al obtener rutas.'
      }).catch(error => {
        if (typeof error != 'string') {
          this.$verifyToken(error.response.data.data);
        } this.showToast('danger', String(error));
      }).finally(() => {
        this.tableOverlay = false
      });
    },
    setLocations() {
      this.data.forEach(route => {
        this.countries.push(route.originCountry);
        this.countries.push(route.destinyCountry);
        this.states.push(route.originState);
        this.states.push(route.destinyState);
      });
    },
    onRowClicked(item) {
      this.$router.push({ name: 'edit-logisticRoute', params: { id: item.id }});
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notificación',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    formatterNumber(value) {
      return utils.numberWithCommas(value);
    },
  },
}
</script>
